import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Sistif V 2.0" link="https://sistif.kemnaker.go.id/" bg="linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(37,37,238,1) 31%, rgba(23,102,244,1) 57%" mdxType="ProjectCard">
This project for collect inform about migrant workers abroad with Ministry of Labor Indonesia  <br /> <br />Tech (PHP, MySQL)
    </ProjectCard>
    <ProjectCard title="Appety" link="https://www.appety.sg" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
This project is for listing and ordering restaurant based on Singapore <br /> <br />Tech (React JS, GraphQL, Objection, Postgres)
    </ProjectCard>
    <ProjectCard title="Splendid" link="https://splend.id/" bg="linear-gradient(to left, ##77A1D3 0%, ##79CBCA 100%)" mdxType="ProjectCard">
This project is Human Resource System (HRS) based on Surabaya <br /> <br />Tech (React JS, GraphQL, Objection, Postgres)
    </ProjectCard>
    <ProjectCard title="TPS3R Project" link="https://tps3r.geeq.tech/" bg="linear-gradient(to right, #20BF55 0%, #01BAEF 100%)" mdxType="ProjectCard">
This project is volenteer for tracking waste bank based on Bali <br /> <br />Tech (React JS, GraphQL, Objection, Postgres)
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      