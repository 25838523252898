import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`Hi, Im Agit Anggriwan.
Software developer based in Surabaya, East Java. Currently as Backend Developer with Javascript. Enjoy taking complex problems and turning into simple, clean, and efficient code.
And sometimes playing Dota 2 with my friends enjoy drink tea, milk, and coffee (but not very often)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      